import * as React from "react"
import Seo from "../components/seo"
import ContentPageLayout from "../components/ContentPageLayout"
import ContentSkeleton from "../components/ContentSkeleton"
import { getHome } from "../api/content"
import Section from "../components/content/section";
import firebase from "firebase";
import config, { firebaseInit } from "../config"
import ChatButton from "../components/buttons/ChatButton"
import { Helmet } from 'react-helmet'

const IndexPage = ({ location }) => {
  const profileUri = config.serverUri
  const [contentLoading, setContentLoading] = React.useState(true)
  const [section, setSection] = React.useState()
  const [userHasSuscription, setUserHasSuscription] = React.useState(null)
  const [sectionSelected, setSectionSelected] = React.useState("home")
  
  const handleSectionFilterChange = (filteredId) => {
    setSectionSelected(filteredId)
  }

  React.useEffect(() => {
    getHome().then(data => {
      setSection(data)
      setContentLoading(false)
    })
  }, [])

  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        fetch(`${profileUri}/users/?email=${user.email}`)
          .then(response => {
            if (response.ok) {
              response.json().then(data => {
                if (data[0] && data[0].suscription) setUserHasSuscription(data[0].suscription)
              })
            }
          }).catch(error => {
            console.log(error)
          }).catch(error => {
            console.log(error)
          })
      } else {
        console.log("user is null")
        setUserHasSuscription(false)
      }
    })
  }, [])

  const suscription = (text) => {
    text === "premium" || text === "free" ? setUserHasSuscription(text) : setUserHasSuscription(false)
  }

  return (<ContentPageLayout suscription={suscription} location={location} section={section} sectionSelected={sectionSelected}>
    <Helmet>
      <script>{
        `!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};ttq.load('CH4N0RRC77UEFTNV1RUG');ttq.page();}(window, document, 'ttq');`
      }
      </script>
    </Helmet>
    <Seo title="Home" />
    <ChatButton />
    {contentLoading || !section ? <ContentSkeleton></ContentSkeleton> : <Section key={section._id} section={section} userHasSuscription={userHasSuscription} suscription={suscription} onFilterChange={handleSectionFilterChange}></Section>}
  </ContentPageLayout>)
}

export default IndexPage
