import React from 'react'
import { Helmet } from 'react-helmet'
import "../../styles/site.css"

const ChatButton = () => {

    const ref = React.useRef(null);

    React.useEffect(() => {
        const chat = document.getElementById('launcher')
        if (chat !== null) {
            chat.style.bottom = "";
            chat.className = "bottom-24 md:bottom-14"
        }
        // console.log(chat);
    })

    return (
        <div className='fixed z-auto bottom-12 md:bottom-4 right-2' style={{ zIndex: '100' }}>
            <div
                className='wa-message-us'
                number='5215538880545'
                label='WhatsApp'
                color='green'
                size='compact'
                border_radius='20px'>
            </div>
            <Helmet>
                <script src="https://cdn.smooch.io/whatsapp/message-us-btn.min.js" type="text/javascript"></script>
                <script id="ze-snippet" ref={ref} src="https://static.zdassets.com/ekr/snippet.js?key=a22182cf-51a0-4b60-87a3-25267c323183"> </script>
                <script type="text/javascript">{`
    window.zESettings = {
      webWidget: {
        offset: { horizontal: '0px', vertical: '70px' }
      }
    };
`}</script>
            </Helmet>
        </div>
    )
}

export default ChatButton;
